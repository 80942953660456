<template>
  <div>
    <div class="user-circle-container">
      <div class="no-results" v-show="total === 0 && !spinShow">
        <img :src="require('@/assets/qietu/article.png')" alt="">
        <p>暂无关注</p>
      </div>
      <Row class="list" :gutter="16">
        <Col :md="24" :lg="24" :xl="24" :xxl="12" v-for="(item, i) in discussList" :key="i">
          <div class="box">
            <div class="title">
              <span>{{ item.title }}</span>
              <Button type="dashed" @click="unfocus(item.discussId)">已关注</Button>
            </div>
            <div class="content">
              {{ item.replyNum }}回复 - 最近回复{{ item.time }}
            </div>
          </div>
        </Col>
      </Row>
      <Spin size="large" fix v-show="spinShow"></Spin>
    </div>
    <Paging
      :total="total"
      :curPage="page"
      :limit="12"
      :showPage="5"
      @changePager="handlePager"
    ></Paging>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Paging from "@/components/paging/index.vue";
export default {
  components: {
    Paging,
  },
  data() {
    return {
      discussList: [],
      page: 1,
      total: 0,
      spinShow: false
    };
  },
  created() {
    this.getFollowDiscussList({
      pageNum: 1,
      pageSize: 12,
    });
  },
  computed: {
    ...mapState(["hasLogin"]),
  },
  methods: {
    async unfocus(id) {
      if (this.hasLogin) {
        // 1 关注 0 取消关注
        let data = {
          attentionId: id,
          type: 0,
        };
        const resp = await this.$apis.circleServe.onFocusPro(data);
        if (resp.resultDesc === "成功!") {
          this.getFollowDiscussList({
            pageNum: this.page,
            pageSize: 12,
          });
          this.$Message.success('取消关注成功');
        } else {
          this.$Message.error(resp.resultDesc);
        }
      } else {
        this.$store.commit("showAlert", true);
      }
    },
    handlePager(i) {
      this.getFollowDiscussList({
        pageNum: i,
        pageSize: 12,
      });
    },
    async getFollowDiscussList(params) {
      this.spinShow = true
      const { data } = await this.$apis.userServe.getFollowDiscussList(params);
      this.discussList = data.list;
      this.page = +data.pageNum;
      this.total = +data.total;
      this.spinShow = false
    },
  },
};
</script>

<style lang="scss" scoped>
.user-circle-container {
  position: relative;
  margin-top: 35px;
  p {
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
  .box {
    margin-bottom: 32px;
    padding: 23px;
    border: 1px solid #e9e9e9;
    border-radius: 16px;
    background: #ffffff;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 20px;
        color: #333333;
        width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .ivu-btn {
        width: 82px;
        height: 34px;
        border: 1px dashed #00a4ff;
        border-radius: 10px;
      }
    }
    .content {
      margin-top: 20px;
      font-size: 14px;
      color: #999999;
      overflow: hidden;
      display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示 。
      text-overflow: ellipsis; // 可以用来多行文本的情况下，用省略号“...”隐藏超出范围的文本 。
      -webkit-box-orient: vertical; // 设置或检索伸缩盒对象的子元素的排列方式 。
      -webkit-line-clamp: 2;
    }
  }
  .no-results {
    margin: 0 auto;
    width: 500px;
    // height: 300px;
    img {
      width: 100%;
    }
    p {
      text-align: center;
      font-size: 16px;
      color: #999999;
    }
  }
}
</style>